<template>
  <div class="page">
    <div class="box">
      <img src="../../assets/assess/success.png" alt="">
      <div v-if="type==='linen'">布草申领单已提交完成!</div>
      <div v-else>物品申领单已提交完成!</div>
    </div>
  </div>
</template>
<script>
export default{
  data(){
    return{
      type:'linen'
    }
  },
  created(){
    this.type=this.$route.query.type
  }

}
</script>
<style lang="scss" scoped>
.page {
  height: 100Vh;

  .box {
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    img {
      margin: 0 auto;
      width: 70px;
      height: 70px;
    }

    div {
      margin-top: 20px;
      font-size: 25px;
      font-weight: bold;
    }

  }
}
</style>